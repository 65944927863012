import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

export const loggedInCheck = createAsyncThunk('users/fetchUser', async (_, thunkAPI) => {
  //const AccessToken = localStorage.getItem('AccessToken');
  const accessToken = thunkAPI.getState().Auth.accessToken;

  try {
    const response = await axios.get('https://beehandbook.ru:4001/getUser', {
      headers: {
        authorization: accessToken,
      },
      withCredentials: true,
      responseType: 'json',
    });

    return { data: response.data, AccessTokenRefresh: response.headers.authorization };
  } catch (err) {
    if (err.response.status === 401 && err.response.data.error === 'Доступ запрещен. Токен доступа просрочен') {
      thunkAPI.dispatch(setAccessToken(err.response.headers.authorization));
      thunkAPI.dispatch(loggedInCheck());
    } else {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
});

export const sessionLogout = createAsyncThunk('users/logout', async (_, thunkAPI) => {
  //const AccessToken = localStorage.getItem('AccessToken');
  const accessToken = thunkAPI.getState().Auth.accessToken;
  try {
    const response = await axios.get('https://beehandbook.ru:4001/logout', {
      headers: {
        authorization: accessToken,
      },
      withCredentials: true,

      responseType: 'json',
    });

    return { data: response.data };
  } catch (err) {
    return thunkAPI.rejectWithValue({ error: err.message });
  }
});

const initialState = {
  user: null,
  wait: true,
  accessToken: null,
};

function clearAllStorage() {
  localStorage.clear();
  sessionStorage.clear();
}

const AuthSlice = createSlice({
  name: 'AuthState',
  initialState,

  reducers: {
    loginUser: (state, action) => {
      state.accessToken = action.payload;
      //localStorage.setItem('AccessToken', action.payload);
    },
    // logout: (state, action) => {
    //   if (window.confirm('Вы Уверены ?')) {
    //
    //     clearAllStorage();
    //     state.user = null;
    //   }
    // },
    forceLogout: (state, action) => {
      clearAllStorage();
      state.accessToken = null;
      state.user = null;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loggedInCheck.pending, (state) => {
      state.wait = true;
    });
    builder.addCase(loggedInCheck.fulfilled, (state, action) => {
      state.wait = true;
      let accessTokenRefresh = action.payload?.AccessTokenRefresh;
      //if (accessTokenRefresh) localStorage.setItem('AccessToken', accessTokenRefresh);
      if (accessTokenRefresh) state.accessToken = accessTokenRefresh;
      state.user = action.payload?.data;
      if (state.user) state.wait = false;
    });
    builder.addCase(loggedInCheck.rejected, (state, action) => {
      clearAllStorage();
      state.accessToken = null;
      state.user = null;
      state.wait = false;
    });
  },
});

export const logout = createAsyncThunk('users/logout', async (params, { dispatch }) => {
  if (window.confirm('Вы Уверены ?')) {
    dispatch(sessionLogout());
    dispatch(forceLogout());
  }
});

export const { loginUser, forceLogout, setAccessToken } = AuthSlice.actions;

export default AuthSlice.reducer;
