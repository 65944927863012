import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { forceLogout, setAccessToken } from '../Slices/AuthSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://beehandbook.ru:4001/',
  credentials: 'include',

  prepareHeaders: (headers, { getState }) => {
    // const AccessToken = localStorage.getItem('AccessToken');
    const AccessToken = getState().Auth.accessToken;
    headers.set('authorization', `${AccessToken}`);
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // let result = await baseQuery(args, api, extraOptions);
  // const AccessToken = localStorage.getItem('AccessToken');
  // const testtoken = api.getState().Auth.accessToken;
  // console.log(testtoken);
  // const accessTokenRefresh = result.meta.response.headers.get('authorization_refresh');
  // if (accessTokenRefresh) localStorage.setItem('AccessToken', accessTokenRefresh);
  // if ((result.error && result.error.status === 401) || (!AccessToken && !accessTokenRefresh)) api.dispatch(forceLogout());

  let result = await baseQuery(args, api, extraOptions);

  const AccessToken = api.getState().Auth.accessToken;
  const accessTokenRefresh = result.meta.response.headers.get('authorization');

  //токен доступа просрочился. Получаем из заголовков новый токен доступа и продолжаем отправлять данные
  if (result?.error?.status === 401 && result?.error?.data?.error === 'Доступ запрещен. Токен доступа просрочен') {
    if (accessTokenRefresh) api.dispatch(setAccessToken(accessTokenRefresh));
    return await baseQuery(args, api, extraOptions);
  }

  //токен обновления просрочился или токенов нет вообще - делаем logout
  if ((result?.error?.status === 401 && result?.error?.data?.error === 'Доступ запрещен. Токен обновления не корректный') || (!AccessToken && !accessTokenRefresh))
    api.dispatch(forceLogout());

  return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const beeHandBookAPI = createApi({
  reducerPath: 'beeHandBookAPI',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
