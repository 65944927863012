import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Typography, Box, Divider, Paper, LinearProgress, Chip, useMediaQuery, useTheme } from '@mui/material';
import UnmountSearchResult from './UI/UnmountSearchResult';
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { resetCheckedArr } from '../../../Redux/Slices/UnmountExecutionSlice';
import { useSaveUnmountRegisterEntryMutation } from '../../../Redux/API/UnmountExecutionAPI';

//import UnmountSelectedCard from './UI/UnmountSelectedCard';

function UnmountExecution() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { accessToken } = useSelector((state) => state.Auth);

  const [unmountDesc, setUnmountDesc] = useState('');
  const [searchResult, setSearchResult] = useState();

  const [load, setLoad] = useState(false);
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const checkedArr = useSelector((state) => state.UnmountExecution.checkedArr);
  const dispatch = useDispatch();

  const [SaveUnmountRegisterEntry] = useSaveUnmountRegisterEntryMutation();

  useEffect(() => {
    dispatch(resetCheckedArr());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let delay;

    if (unmountDesc.trim() !== '' && unmountDesc.length > 2) {
      delay = setTimeout(() => {
        setLoad(true);
        setSearchResult('');

        axios
          .get('https://beehandbook.ru:4001/UnmountSearch?SearchString=' + unmountDesc, {
            headers: {
              Authorization: accessToken,
            },
            withCredentials: true,
          })
          .then((data) => {
            setLoad(false);

            const resultArray = data.data.map((res) => (res = { ...res, unmounted_state: 'Неисправно', unmounted_state_description: '' }));

            setSearchResult(resultArray);
          });
      }, 700);
    } else {
      setLoad(false);
    }
    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [unmountDesc]);

  async function unmountExecutionBtnHandler() {
    if (checkedArr.length !== 0) {
      setSaving(true);
      SaveUnmountRegisterEntry({ checkedArr }).then((fulfilled) => {
        setSaving(false);
        if (fulfilled.data.res === 'Saved successfully!') navigate('../UnmountRegister');
      });
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Chip
            label={mobileView ? 'Оформление демонтированного оборудования' : 'Оформление демонтированного с сети оборудования'}
            sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
          />
          <Paper elevation={24} sx={{ borderTopLeftRadius: '0px' }}>
            <Box sx={{ width: 'auto', minHeight: '80vh', padding: '10px' }}>
              <TextField
                fullWidth
                label='Поиск демонтированного оборудования'
                variant='outlined'
                helperText={
                  mobileView
                    ? 'Введите наименование, код НФС, серийный или инвентарный номер, код ЕРП'
                    : 'Введите наименование демонтированного оборудования, код НФС, серийный или инвентарный номер, код ЕРП позиции для поиска'
                }
                size='small'
                value={unmountDesc}
                onChange={(e) => {
                  setSearchResult('');
                  setUnmountDesc(e.target.value);
                }}
              />
              {load && <LinearProgress />}
              <UnmountSearchResult data={searchResult} handler={unmountExecutionBtnHandler} saving={saving} />
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
}

export default UnmountExecution;
