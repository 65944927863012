import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { resetCheckedArr, toggleCheckGlobal } from '../../Redux/Slices/UnmountExecutionSlice';
import { useGetUnmountRegisterQuery } from '../../Redux/API/UnmountExecutionAPI';
import { useUnmountRegisterUserActionMutation } from '../../Redux/API/UnmountExecutionAPI';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  LinearProgress,
  Button,
  ButtonGroup,
  Tooltip,
  TablePagination,
  Chip,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import MySelect from './UnmountExecution/UI/MySelect';
import MyCheckbox from './UnmountExecution/UI/MyCheckbox';

function UnmountRegister() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { accessToken } = useSelector((state) => state.Auth);

  const [unmountRegisterData, setUnmountRegisterData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const { user } = useSelector((state) => state.Auth);
  const { globalCheck, checkedArr } = useSelector((state) => state.UnmountExecution);
  const dispatch = useDispatch();
  const { data: unmountData, isFetching, isLoading, refetch: reloadUnmountData } = useGetUnmountRegisterQuery({ page, limit: rowsPerPage }, { refetchOnMountOrArgChange: true });
  const [dispatchUnmountRegisterAction] = useUnmountRegisterUserActionMutation();

  useEffect(() => {
    dispatch(resetCheckedArr());
    // eslint-disable-next-line
  }, []);

  async function UnmountRegisterUserAction(action) {
    let workCheckedArr = checkedArr;

    // const AccessToken = localStorage.getItem('AccessToken');

    if (action === 'switch_to_acted' || action === 'switch_to_no_acted' || action === 'save_state' || action === 'delete') {
      setActionLoading(true);
      dispatchUnmountRegisterAction({ action, checkedArr: workCheckedArr });
      dispatch(resetCheckedArr());
      setActionLoading(false);
    } else {
      setActionLoading(true);
      axios
        .post(
          'https://beehandbook.ru:4001/UnmountRegisterUserAction',
          {
            action,
            checkedArr: workCheckedArr,
          },

          {
            headers: {
              Authorization: accessToken,
            },
            responseType: 'blob',
            withCredentials: true,
          }
        )
        .then((response) => {
          const currDate = new Date().toISOString().split('T')[0];
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `Комплект_документов_возврат_${currDate.split('-')[2] + '.' + currDate.split('-')[1] + '.' + currDate.split('-')[0]}.zip`);
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          if (action === 'download_act') {
            setTimeout(() => {
              reloadUnmountData();
              //GetUnmountRegister();
            }, 1000);
          }
          setActionLoading(false);
        });
    }
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Chip
            label={mobileView ? 'Реестр демонтированного оборудования' : 'Реестр учета демонтированного с сети оборудования'}
            sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
          />
          <Paper elevation={24} sx={{ borderTopLeftRadius: '0px' }}>
            <Box sx={{ width: 'auto', minHeight: '80vh', padding: '10px' }}>
              {isLoading && <LinearProgress />}
              {unmountData?.data && unmountData?.data !== '' && (
                <>
                  <TableContainer component={Paper} sx={{ maxHeight: '67vh', marginTop: '10px' }}>
                    <Table aria-label='simple table' stickyHeader size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              checked={globalCheck}
                              onChange={() => {
                                dispatch(toggleCheckGlobal(unmountData.data));
                              }}
                              size='small'
                            />
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: '12px' }}>
                            <b>Филиал</b>
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: '12px' }}>
                            <b>Дата демонтажа</b>
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: '12px' }}>
                            <b>Описание</b>
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: '12px' }}>
                            <b>Кол-во</b>
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: '12px' }}>
                            <b>ед. изм.</b>
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: '12px' }}>
                            <b>Серийный номер</b>
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: '12px' }}>
                            <b>партия</b>
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: '12px' }}>
                            <b>Код НФС</b>
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: '12px' }}>
                            <b>Новый код НФС</b>
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: '12px' }}>
                            <b>Инвентарный номер</b>
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: '12px' }}>
                            <b>Актив</b>
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: '12px' }}>
                            <b>ЕРП поз. демонтажа</b>
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: '12px' }}>
                            <b>ГФК поз. демонтажа</b>
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: '12px', minWidth: '80px' }}>
                            <b>Состояние</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ fontSize: '8px' }}>
                        {unmountData.data.map((res, i) => (
                          <TableRow
                            key={i}
                            sx={[
                              { '&:last-child td, &:last-child th': { border: 0 } },

                              res.isActed === 'true' ? { td: { fontWeight: 100, color: 'lightgrey' } } : { td: { fontWeight: 700 } },
                            ]}
                            hover
                            onClick={() => {}}
                          >
                            <TableCell>
                              <MyCheckbox res={res} />
                            </TableCell>
                            <TableCell sx={{ fontSize: '10px' }} align='center'>
                              {res.filial}
                            </TableCell>
                            <TableCell sx={{ fontSize: '10px' }} align='center'>
                              {res.unmount_date.split('-')[2] + '.' + res.unmount_date?.split('-')[1] + '.' + res.unmount_date?.split('-')[0]}
                            </TableCell>
                            <TableCell sx={{ fontSize: '10px' }} align='left'>
                              {res.unmounted_description}
                            </TableCell>
                            <TableCell sx={{ fontSize: '10px' }} align='center'>
                              {res.unmounted_Qty}
                            </TableCell>
                            <TableCell sx={{ fontSize: '10px' }} align='center'>
                              {res.unmounted_Qty_type}
                            </TableCell>
                            <TableCell sx={{ fontSize: '10px' }} align='center'>
                              {res.unmounted_SN}
                            </TableCell>
                            <TableCell sx={{ fontSize: '10px' }} align='center'>
                              {res.unmounted_part}
                            </TableCell>
                            <TableCell sx={{ fontSize: '10px' }} align='center'>
                              {res.unmounted_NFS}
                            </TableCell>
                            <TableCell sx={{ fontSize: '10px' }} align='center'>
                              {res.unmounted_New_NFS}
                            </TableCell>
                            <TableCell sx={{ fontSize: '10px' }} align='center'>
                              {res.unmounted_Inventory_num}
                            </TableCell>

                            <TableCell sx={{ fontSize: '10px' }} align='center'>
                              {res.unmounted_active}
                            </TableCell>
                            <TableCell sx={{ fontSize: '10px' }} align='center'>
                              <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}> {res.position} </span>}>
                                <span>{res.unmounted_ERP}</span>
                              </Tooltip>
                            </TableCell>
                            <TableCell sx={{ fontSize: '10px' }} align='center'>
                              <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}> {res.position}</span>}>
                                <span> {res.unmounted_GFK}</span>
                              </Tooltip>
                            </TableCell>
                            <TableCell sx={{ fontSize: '10px' }} align='center'>
                              {checkedArr.includes(checkedArr.find((elem) => elem.ID === res.ID)) ? (
                                <MySelect res={res} />
                              ) : (
                                <Tooltip title={res.unmounted_state_description}>
                                  <span>
                                    {res.unmounted_state}{' '}
                                    {res.unmounted_state_description !== '' && (
                                      <span style={{ color: 'red', fontSize: '15px' }}>
                                        <b>*</b>
                                      </span>
                                    )}
                                  </span>
                                </Tooltip>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    labelRowsPerPage={'Всего строк на странице'}
                    labelDisplayedRows={(page) => `Строки с ${page.from} по ${page.to === -1 ? page.count : page.to} из ${page.count}`}
                    component='div'
                    count={unmountData ? unmountData.totalRowsCount : -1}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}

              <Box align='right' mt='10px'>
                <ButtonGroup size='small' disabled={checkedArr.length === 0}>
                  {user.role === 'Admin' && (
                    <Button
                      sx={{ fontSize: mobileView ? '7px' : '9px' }}
                      color='error'
                      onClick={() => {
                        UnmountRegisterUserAction('delete');
                      }}
                    >
                      Удалить
                    </Button>
                  )}
                  <Button
                    sx={{ fontSize: mobileView ? '7px' : '9px' }}
                    onClick={() => {
                      UnmountRegisterUserAction('download_act');
                    }}
                  >
                    Выгрузить комплект документов
                  </Button>

                  <Button
                    sx={{ fontSize: mobileView ? '7px' : '9px' }}
                    onClick={() => {
                      UnmountRegisterUserAction('switch_to_acted');
                    }}
                  >
                    Отметить как обработанный
                  </Button>
                  <Button
                    sx={{ fontSize: mobileView ? '7px' : '9px' }}
                    onClick={() => {
                      UnmountRegisterUserAction('switch_to_no_acted');
                    }}
                  >
                    Отметить как не обработанный
                  </Button>

                  <Button
                    sx={{ fontSize: mobileView ? '7px' : '9px' }}
                    onClick={() => {
                      UnmountRegisterUserAction('save_state');
                    }}
                  >
                    Сохранить состояние
                  </Button>
                </ButtonGroup>
                {actionLoading && <LinearProgress sx={{ marginTop: '10px' }} />}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
}

export default UnmountRegister;
